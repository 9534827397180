<template>
  <el-drawer :visible.sync="drawer" :size="1200" @close="editClose()">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">编辑题库资源</div>
      <div class="drawer-title-btns">
        <el-button size="small" @click="editClose()">取消</el-button>
        <el-button size="small" type="primary" @click="editSubmit()">确定</el-button>
      </div>
    </div>
    <div style="padding: 20px; height: 100%;">
      <el-form ref="editFormRef" :model="editForm" label-width="80px" size="small" :rules="editFormRule">
        <!-- <el-form-item label="题库类型">
          <el-radio-group v-model="editForm.questionType" @change="editQuestionTypeChange">
            <el-radio :label="item.id" v-for="item in typeList" :key="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="题库" prop="category_id">
          <el-cascader class="cascader" v-model="editForm.categoryCasList" :show-all-levels="false" filterable
            :options="editCategoryTreeList" :props="optionProps" @change="editHandleChange" placeholder="请选择题库分类">
          </el-cascader>
        </el-form-item> -->
        <el-form-item label="资源类型" prop="type">
          <el-radio-group v-model="editForm.type" @change="editLibTypeChange">
            <el-radio :label="item.id" v-for="item in editLibTypeList" :key="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传类型">
          <el-radio-group v-model="editForm.uploadType">
            <el-radio :label="item.id" v-for="item in uploadTypeList" :key="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="资源" prop="externalResources">
          <el-table
            :data="[{}]"
            border
            style="width: 100%">
            <el-table-column
              prop=""
              label="名称">
                <template>
                  <el-input v-model="editForm.name" type="textarea" style="margin-right: 10px;"
                    :placeholder="editForm.uploadType === '1' ? '请输入资源名称' : '请输入外部资源名称'"></el-input>
                </template>
            </el-table-column>
            <el-table-column
              prop=""
              :label="editForm.uploadType==='1'?'链接':'路径'">
                <template>
                  <el-input v-model="editForm.path" type="textarea" :placeholder="editForm.uploadType === '1' ? '请输入链接' : '请输入外部链接地址'"
                    :disabled="editForm.uploadType === '1'"></el-input>
                </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="文件" prop="uploadfile_id" key="uploadfile_id" v-show="editForm.uploadType==='1'">
          <!-- <el-upload v-show="!uploadLoading" style="margin-right:20px;" class="avatar-uploader"
            :action="constant.URL + '/uploadfile/upload'" :headers="{ 'access-token': Cookies.get('liteAccessToken') }"
            :on-progress="uploadProgress" :before-upload="beforeUpload" :accept="editForm.type === '1' ? '.mp4' : '.pdf'"
            :show-file-list="false" :on-success="editUploadSuccess">
            <div class="avatar-uploader-icon">重新上传文件</div>
          </el-upload> -->
          <!-- <div v-show="uploadLoading" class="edit-upload-progress">
            <el-progress type="circle" :percentage="percentage"></el-progress>
          </div> -->
          <UploadView :idn="'upload2'" style="height: 32px; width: 115px;" :btnTitle="'重新上传文件'"
            @success="editUploadSuccess" @error="handleError" :accept="editForm.type === '1' ? 'mp4' : 'pdf'"
            v-loading="uploadLoading" @beforeUpload="beforeUpload()" :multiple="false" />
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script setup>
import { reactive, ref, watch, getCurrentInstance } from "vue";
// import Cookies from "js-cookie";
import UploadView from "@/components/UploadView.vue";
// 当前组件实例对象
const instance = getCurrentInstance();
// 定义接收的props
const props = defineProps({
  drawerShow: {
    type: Boolean,
    default: false
  },
  libId: {
    type: String,
    required: true
  }
});
// 定义接收的事件
const emit = defineEmits(["cancel", "submit"]);

// 抽屉的可见性
const drawer = ref(false);

// 表单实例
const editFormRef = ref();
// 编辑的表单数据
const editForm = reactive({
  id: "",
  category_id: "",
  type: "",
  path: "",
  uploadfile_id: "",
  link: "",
  name: "",
  first_category_id: "",
  second_category_id: "",
  categoryCasList: [],
  questionType: "",
  uploadType: "1"
});
const uploadTypeList = ref([
  {
    id: "1",
    name: "本地上传"
  },
  {
    id: "2",
    name: "外部链接"
  }
]);
// 资源类型数据
const editLibTypeList = ref([]);
// 获取资源类型数据
const getLibType = () => {
  instance.proxy.api.dataset.libType().then(res => {
    const arr = [];
    Object.keys(res.types).forEach(key => {
      arr.push({
        id: key,
        name: res.types[key]
      });
    });
    editLibTypeList.value = arr;
  });
}
getLibType();

// 文件是否重新上传
const reUpload = ref(false);
// 文件是否正在上传
const uploadLoading = ref(false);
// 上传文件进度
const percentage = ref(0);
// 编辑上传文件之前钩子
const beforeUpload = () => {
  percentage.value = 0;
  uploadLoading.value = true;
}
// 文件上传中钩子
const uploadProgress = (event) => {
  if (event.lengthComputable) {
    percentage.value = Math.round(event.loaded / event.total * 100);
  }
}
// 编辑抽屉上传文件成功
const editUploadSuccess = (res) => {
  if (res?.data?.info) {
    editForm.uploadfile_id = res.data.info.id || "";
    editForm.path = res.data.info.upload_path || "";
    editForm.name = res.data.info.name || "";
  } else {
    editForm.uploadfile_id = res.info.id || "";
    editForm.path = res.info.upload_path || "";
    editForm.name = res.info.name || "";
  }
  uploadLoading.value = false;
  percentage.value = 0;
}
// 文件上传失败
const handleError = (error) => {
  uploadLoading.value = false;
  if (error) {
    instance.proxy.$Message.error(error);
  }
}

// 编辑抽屉的资源类型改变
const editLibTypeChange = () => {
  editForm.path = "";
  editForm.name = "";
  editForm.link = "";
  reUpload.value = true;
  editFormRef.value.validateField("uploadfile_id");
}

// 题库级联选择器配置
// const optionProps = {
//   value: "child_id",
//   label: "name",
//   children: "children",
//   expandTrigger: 'hover'
// }
// 题库类型变化事件
// const editQuestionTypeChange = (value) => {
//   editForm.category_id = "";
//   editForm.categoryCasList = [];
//   getEditCategoryTreeData(value);
// }
// 题库改变时的回调
// const editHandleChange = (value) => {
//   editForm.category_id = value[2];
// }

// 分类的树数据
const editCategoryTreeList = ref([]);
// 获取分类树数据
const getEditCategoryTreeData = (type) => {
  instance.proxy.api.home.categoryTree({
    site_id: "-1",
    type: type || "0"
  }).then((res) => {
    editCategoryTreeList.value = res.tree || [];
  });
}
// 调用
getEditCategoryTreeData();

// 验证上传的文件
const validateEditUpload = (rule, value, callback) => {
  if (reUpload.value && !editForm.uploadfile_id) {
    return callback("资源类型已变动，请重新上传文件");
  }
  callback();
}
// 验证路径
const validateEditPath = (rule, value, callback) => {
  if (!value) {
    return callback("请输入链接");
  }
  callback();
}
const validateExternal = (rule, value, callback) => {
  if (!editForm.name) {
    return callback("请输入名称");
  }
  if (!editForm.path) {
    return callback("请输入链接");
  }
  callback();
}
// 表单的验证规则
const editFormRule = {
  name: [
    { required: true, message: "请输入资源名称", trigger: "blur" }
  ],
  category_id: [
    { required: true, message: "请选择题库", trigger: "blur" }
  ],
  type: [
    { required: true, message: "请选择资源类型", trigger: "blur" }
  ],
  path: [
    { validator: validateEditPath, trigger: "blur" }
  ],
  uploadfile_id: [
    { validator: validateEditUpload, trigger: "blur" }
  ],
  externalResources: [
    { validator: validateExternal, trigger: "blur" }
  ]
}

// 编辑对话框确定按钮
const editSubmit = () => {
  const params = editForm;
  params.site_id = "-1";
  editFormRef.value.validate(valid => {
    if (valid) {
      instance.proxy.api.dataset.updateLib(params).then(() => {
        emit("submit");
        instance.proxy.$Message.success("编辑成功");
      });
    }
  });
}
// 编辑抽屉关闭
const editClose = () => {
  Object.keys(editForm).forEach(key => {
    editForm[key] = "";
  });
  editForm.uploadType = "1";
  editFormRef.value.resetFields();
  reUpload.value = false;
  emit("cancel");
}

// 获取编辑详情信息
const getDetail = () => {
  instance.proxy.api.dataset.getLibDetail({
    id: props.libId
  }).then(res => {
    Object.keys(editForm).forEach(key => {
      editForm[key] = res.info[key] || "";
    });
    editForm.uploadType = "1";
    editForm.categoryCasList = [res.info.first_category_id, res.info.second_category_id, res.info.category_id];
    editForm.questionType = res.category.type;
    getEditCategoryTreeData(editForm.questionType);
  });
}
// 监听是否显示抽屉
watch(() => props.drawerShow, (newValue) => {
  drawer.value = newValue;
  if (newValue) {
    getDetail();
  }
}, { immediate: true });

// 题库类型分类数据
const typeList = ref([]);
// 获取题库分类数据
const getCategoryData = () => {
  instance.proxy.api.dataset.categoryForm().then((res) => {
    // 处理类型数据
    const arr = [];
    Object.keys(res.categoryTypes).forEach(key => {
      arr.push({
        id: key,
        name: res.categoryTypes[key]
      });
    });
    typeList.value = arr;
  });
}
getCategoryData();
</script>

<style lang="scss" scoped></style>