<template>
  <div class="resources">
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="resources-main">
      <!-- <UploadView></UploadView> -->
      <div class="resources-main-top">
        <el-button type="primary" size="small" @click="drawer = true">添加题库资源</el-button>
        <div class="top-right">
          <Input v-model="searchForm.keyword" :placeholder="$t('common_search_keyword')" clearable search
            :enter-button="true" @on-enter="changeKeyword" @on-search="changeKeyword" />
        </div>
      </div>
      <div class="search">
        <!-- <div class="search-item">
          <div class="name">
            题库类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="searchForm.type == item.id ? 'active' : ''" v-for="(item, index) in typeList"
                :key="index + 'type'" @click="changeSearch(item, 'type')">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="searchForm.category_id == item.id ? 'active' : ''" v-for="item in category1List"
                :key="item.id + 'category_id'" @click="changeSearch(item, 'category_id')">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="category2List.length > 1">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="searchForm.category_second_id == item.id ? 'active' : ''" v-for="item in category2List"
                :key="item.id + 'category_second_id'" @click="changeSearch(item, 'category_second_id')">{{ item.name }}
              </div>
            </div>
          </div>
        </div> -->
        <div class="search-item">
          <div class="name">
            资源类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="searchForm.libType == item.id ? 'active' : ''" v-for="(item, index) in libTypeList"
                :key="index + 'type'" @click="changeSearch(item, 'libType')">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableList" border>
          <el-table-column prop="id" label="序号" width="80">
          </el-table-column>
          <el-table-column prop="name" label="名称">
          </el-table-column>
          <el-table-column prop="type" label="类型" width="100">
            <template v-slot="{ row }">
              {{ libTypeList.find(item => item.id === row.type)?.name || '' }}
            </template>
          </el-table-column>
          <el-table-column prop="path" label="路径">
          </el-table-column>
          <el-table-column prop="address" label="操作" width="220">
            <template v-slot="{ row }">
              <el-button type="primary" size="small" @click="preview(row)">预览</el-button>
              <el-button type="warning" size="small" @click="edit(row)">编辑</el-button>
              <el-button type="danger" size="small" @click="delLib(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagin style="text-align: end;" @currentChange="currentChange" @sizeChange="sizeChange" :pagination="pagination" />
    </div>
    <!-- 预览资源抽屉 -->
    <el-drawer title="预览" :visible.sync="previewDrawer" :destroy-on-close="true" size="80%" @closed="previewDrawerClose()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">预览</div>
      </div>
      <div style="padding: 20px; height: 100%;">
        <video v-if="previewData.type === '1'" :src="previewData.previewSrc" controls width="100%" height="100%"
          style="background-color: black;" controlslist="nodownload"></video>
        <embed v-else-if="previewData.type !== '1' && !webofficeShow" :src="previewData.previewSrc" id="embed2" width="100%" height="100%" type="application/pdf" />
        <div ref="webOffice" id="webOffice" style="height: 100%;" v-else></div>
      </div>
    </el-drawer>
    <!-- 添加题库资源抽屉组件 -->
    <AddLibDrawer :drawerShow="drawer" :drawerData="searchForm" @cancel="drawerClose()" @submit="submit()" />
    <!-- 编辑题库资源抽屉 -->
    <EditLibDrawer :drawerShow="editDrawer" :libId="libId" @cancel="editClose()" @submit="editSubmit()" />
  </div>
</template>
<script>
import Pagin from "../../components/myPagin.vue";
import AddLibDrawer from "./components/common/addLibDrawer.vue";
import EditLibDrawer from "./components/common/editLibDrawer.vue";
import { MessageBox } from 'element-ui';
import WebOfficeSDK from "@/assets/js/webOffice.js";
// import UploadView from '@/components/UploadView'
export default {
  components: {
    Pagin,
    AddLibDrawer,
    EditLibDrawer,
    // UploadView
  },
  data() {
    return {
      // 编辑抽屉可见性
      editDrawer: false,
      libId: "",
      // 搜索表单数据
      searchForm: {
        type: "0",
        libType: "0",
        site_id: "-1",
        user_id: "-1",
        category_id: "-1",
        category_second_id: "-1",
        keyword: ""
      },
      // 分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      // 资源类型数据
      libTypeList: [],
      // 类型数据
      typeList: [],
      // 一级分类数据
      category1List: [],
      // 二级分类数据
      category2List: [],
      // 表格数据
      tableList: [],
      // 添加资源抽屉可见性
      drawer: false,
      // 预览抽屉可见性
      previewDrawer: false,
      previewData: {
        type: "",
        previewSrc: ""
      },
      WebOfficeInstance:null,
      webofficeShow:false
    }
  },
  methods: {
    // 获取分类数据
    getCategoryData() {
      const { searchForm } = this;
      this.api.dataset.categoryForm(searchForm).then((res) => {
        // 处理类型数据
        const arr = [];
        Object.keys(res.categoryTypes).forEach(key => {
          arr.push({
            id: key,
            name: res.categoryTypes[key]
          });
        });
        this.typeList = arr;
        // 一级分类数据
        this.category1List = res.categoryFirstList || [];
        this.category1List.unshift({
          name: "全部",
          id: "-1"
        });
        // 二级分类数据
        this.category2List = res.categorySecondList || [];
        this.category2List.unshift({
          name: "全部",
          id: "-1"
        });
      });
    },
    // 编辑对话框确定按钮
    editSubmit() {
      this.getList();
      this.editDrawer = false;
    },
    // 编辑抽屉关闭
    editClose() {
      this.editDrawer = false;
    },
    // 获取列表数据
    getList() {
      const { pagination, searchForm } = this;
      this.api.dataset.datasetLibList({
        ...pagination,
        // category_type: searchForm.type,
        type: searchForm.libType,
        // category_id: searchForm.category_second_id && searchForm.category_second_id !== "-1" ? searchForm.category_second_id : searchForm.category_id,
        site_id: "-1",
        user: "-1",
        keyword:searchForm.keyword
      }).then(res => {
        this.tableList = res.list || [];
        const arr = [];
        Object.keys(res.types).forEach(key => {
          arr.push({
            id: key,
            name: res.types[key]
          });
        });
        arr.unshift({
          id: "0",
          name: "全部"
        });
        this.libTypeList = arr;
        pagination.total = Number(res.count);
      });
    },
    changeKeyword() {
      const { pagination } = this;
      pagination.page = 1;
      this.getList();
    },
    // 搜索数据改变
    changeSearch(item, name) {
      const { searchForm } = this;
      // if (name === "type") {
      //   searchForm.category_id = "-1";
      //   searchForm.category_second_id = "-1";
      // }
      // if (name === "category_id") {
      //   searchForm.category_second_id = "-1";
      // }
      searchForm[name] = item.id;
      // this.getCategoryData();
      this.getList();
    },
    // 当前页改变
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getList();
    },
    // 每页条数改变
    sizeChange(pageSize) {
      const { pagination } = this;
      pagination.pageSize = pageSize;
      this.getList();
    },
    // 确定添加
    submit() {
      this.drawer = false;
      this.getList();
    },
    // 抽屉关闭
    drawerClose() {
      this.drawer = false;
    },
    // 删除
    delLib(row) {
      MessageBox.confirm('确定删除当前资源吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.dataset.deleteLib({
          id: row.id,
          site_id:"-1"
        }).then(() => {
          this.$Message.success("删除成功");
          this.getList();
        });
      });
    },
    // 预览
    preview(row) {
      console.log(row);
      const { previewData } = this;
      previewData.previewSrc = row.show_url;
      previewData.type = row.type;
      if (/http/.test(row.show_url)) {
        previewData.previewSrc = row.show_url;
        this.webofficeShow = false;
      } else {
        const webOfficeData = JSON.parse(row.show_url);
        this.webofficeShow = true;
        setTimeout(() => {
          this.WebOfficeInstance = WebOfficeSDK.init({
            ...webOfficeData,
            mount: this.$refs.webOffice
          });
        }, 0);
      }
      this.previewDrawer = true;
    },
    previewDrawerClose(){
      this.webofficeShow = false;
    },
    // 编辑
    edit(row) {
      this.libId = row.id;
      this.editDrawer = true;
    },
  },
  created() {
    // this.getCategoryData();
    this.getList();
  }
}
</script>
<style lang="scss" scoped>
.resources {
  .breadcrumb {
    margin: 20px 0 0 20px;
  }

  .resources-main {
    background-color: white;
    border-radius: 4px;
    margin: 20px;
    padding: 20px;

    .resources-main-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search {
      margin-top: 20px;
      margin-bottom: 20px;

      .search-item {
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;

        .name {
          padding: 5px 0;
          width: 75px;
          text-align: right;
        }

        .cont {
          margin-left: 15px;
          flex: 1;
          display: flex;
          justify-content: space-between;

          .values {
            display: flex;
            justify-content: flex-start;
            flex-flow: wrap;
            transition: max-height 0.3s linear;
            overflow: hidden;

            >div {
              padding: 5px 10px;
              //margin: 0 10px 10px 5px;
              cursor: pointer;
            }

            >div.active {
              background-color: #2d8cf0;
              color: #FFFFFF;
              border-radius: 4px;
            }
          }

          .more {
            padding: 5px 0;
            width: 80px;
            text-align: right;
            cursor: pointer;

            .more-icon {
              transition: transform 0.3s linear;
            }
          }
        }
      }
    }

    .table {
      margin-bottom: 20px;
    }
  }

  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  ::v-deep .avatar-uploader-icon {
    font-size: 20px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .upload-loading {
    height: 178px;
    width: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-upload-progress {
    height: 178px;
    width: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
